import { template } from '@shein/common-function'
import { fetchChemicalsIdsApi } from 'public/src/services/api/pay/checkout.js'
export async function chemicalsIdsEvt(caculate_info, goods_sn){
  let p65Text = ''
  let { p65_flag = [] } = caculate_info || {}
  // 商品sku集合
  let skuArr = p65_flag?.filter(item => item.flag == 1 && item.goods_sn == goods_sn)
  if (skuArr?.length) {
    let tagsId = skuArr?.[0]?.tags || []
    // 商品tag集合
    if (tagsId?.length) {
      tagsId = tagsId?.sort((prev, next) => Number(prev) - Number(next))
      let matchTagIds = tagsId?.[tagsId.length - 1]
      p65Text = await fetchChemicalsIdsApi({ 
        matchTagIds: matchTagIds
      })
    }
  }
  return p65Text
}

export function defaultP65TipsContent (language = {}) {
  const aDom = '<a href="https://www.p65warnings.ca.gov/" target="_blank">www.P65Warnings.ca.gov</a>'
  const txt = template(aDom, language.SHEIN_KEY_PWA_20812)
  return txt
}
