<template>
  <div
    class="quickShip"
    :style="styleCustomize"
  >
    <Icon
      name="sui_icon_qucikship_flat_24px"
      :size="styleCustomize['icon-size']"
      :color="styleCustomize.color"
    />
    <em class="quickShipText">{{ text }}</em>
    <div v-if="this.showLine" class="quickShipLine" />
  </div>
</template>
<script setup>
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  styleConfig: {
    type: Object,
    default: () => ({})
  },
  text: {
    type: String,
    default: 'quickShip'
  },
  showLine: {
    type: Boolean,
    default: false 
  }
})

const styleCustomize = {
  'color': '#FFF',
  'background-color': '#198055',
  'font-size': '12px',
  ...props.styleConfig
}

</script>
<style lang="less">
  .quickShip{
    display: flex;
    width: fit-content;
    max-width: 100%;
    padding: 1px 3px;
    box-sizing: border-box;
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    font-weight: 508;
    font-family: "SF Pro";
  }
  .quickShipText{
    font-style: italic;
    margin-left: 2px;
    padding-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .quickShipLine {
    width: 1px;
    height: .4rem;
    background: #8DC2AB;
    margin-left: 8px;
  }
</style>
